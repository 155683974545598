.settingNav {

    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 250px;

    box-shadow: rgba(0, 27, 71, 0.12) 0px 2px 6px;
    background: rgb(245, 247, 249);
    min-height: calc(100vh - 60px);
    
    
    ul {

        list-style: none;
        margin: 0;
        padding: 0;

        p{
            color: rgb(100, 116, 145);
            padding: 19px 12px 9px;
            font-size: 11px;
            line-height: 14px;
            letter-spacing: -0.01em;
            font-weight: bold;
            text-transform: uppercase;
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            align-items: center;
        }

    }

    
    .top {
        
    }
    .bottom {

    }

}

.li-button {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    // padding-top: 20px;
    cursor: pointer;
    &:hover{
        background-color: #dce9ff;
    }
    .icon {
        font-size: 18px;
        color: rgb(100, 116, 145);
        margin-right: 10px;
    }      
}
.li-nested-button {
    
    display: flex;
    align-items: center;
    padding: 5px 20px;
    // padding-top: 20px;
    cursor: pointer;
    &:hover{
        background-color: #dce9ff;
    }
    .icon {
        font-size: 18px;
        color: rgb(100, 116, 145);
        margin-right: 10px;
    }      
}
li {
    color: #647491;
    &:hover{
        color: black;
    }
}