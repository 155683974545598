.forms {
    display: flex;
    flex-direction: column;
    column-gap: 15px;
    .input-form {
        display: flex;
        flex-direction: column;
        column-gap: 15px;
        .input-password {
            display: flex;
            position: relative;
        }
        .login-button {
            padding: 10px 15px;
            border: 0px solid;
            background-color: #1E59E1; 
            border-radius: 3px;
            color: white;

        }
    }
    .switch2signup {
        width: 100%;
        text-align: center;
        margin-top: 15px;
    }
}