.training {
  display: flex;
  background-color: #eff2f6;
  .trainingContainer {
    flex: 30;
  }
  .training_content {
    display: flex;
  }
}
.trainingNav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 250px;

  box-shadow: rgba(0, 27, 71, 0.12) 0px 2px 6px;
  background: rgb(245, 247, 249);
  min-height: calc(100vh - 60px);

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    p {
      color: rgb(100, 116, 145);
      padding: 19px 12px 9px;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: -0.01em;
      font-weight: bold;
      text-transform: uppercase;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: center;
    }
  }

  .top {
  }
  .bottom {
  }
}

.li-button {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  // padding-top: 20px;
  cursor: pointer;
  &:hover {
    background-color: #dce9ff;
  }
  .icon {
    font-size: 18px;
    color: rgb(100, 116, 145);
    margin-right: 10px;
  }
}
.li-nested-button {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  // padding-top: 20px;
  cursor: pointer;
  &:hover {
    background-color: #dce9ff;
  }
  .icon {
    font-size: 18px;
    color: rgb(100, 116, 145);
    margin-right: 10px;
  }
}
li {
  color: #647491;
  &:hover {
    color: black;
  }
}

.fromWebsite {
  display: flex;
  width: 100%;
  .website {
    margin-left: auto;
    margin-right: auto;
  }
}
.urlForm {
  width: 400px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
}
.manual-widget {
  display: flex;
  flex-direction: column;
  border: none;
  width: 500px;
  margin: auto;
  height: 80vh;
  justify-content: space-between;
  .manual-window {
    height: 500px;
    overflow: auto;

    .user-message {
      background: #0041b9;
      margin-left: 10px;
    }
    .chatbot-message {
      position: relative;
      display: flex;

      .like {
        background: transparent;
        border: none;
        position: absolute;
        margin-left: 30px;
      }
      .dislike {
        background: transparent;
        border: none;
        position: absolute;
        margin-left: 30px;
        margin-top: 50px;
      }
    }
    .correct-answer {
      margin-top: 50px;
      display: flex;
    }
  }
  .user-training-input {
    width: 500px;
    display: flex;
  }
}
